import React from 'react';
import { Flex, Text } from 'rebass';
import { useSpring, animated } from 'react-spring';

import Container from './Shared/Container';

export default function Thank() {
  const props = useSpring({
    from: { opacity: 0, transform: 'translateY(30px)' },
    opacity: 1,
    transform: 'translateY(0px)'
  });
  const AnimatedFlex = animated(Flex);

  return (
    <Container
      maxWidth="950px"
      style={{ transform: 'translate3d(0,-100px,0)' }}
    >
      <AnimatedFlex
        style={props}
        flexDirection="column"
        alignItems="center"
        pt={5}
        px={4}
      >
        <Flex
          width={[1, 3 / 4]}
          p={4}
          backgroundColor="white"
          css={{
            boxShadow: '2px 2px 10px -5px rgba(0, 0, 0, 0.3)',
            border: '1px solid rgba(0,0,0,0.05)',
            borderRadius: 20
          }}
          alignItems="center"
          flexDirection="column"
        >
          <Text fontSize={4} fontWeight={500}>
            Thank you for subscribing
          </Text>
          <Text fontSize={2} fontWeight={300} my={4}>
            You'll revieve an email with the latest security bit I've to share
            rightaway.
          </Text>
        </Flex>
      </AnimatedFlex>
    </Container>
  );
}
